import { Component, Input, OnInit } from "@angular/core";
import { AuthorizationService } from "../../../../platform-pages/src/lib/api/authorization/authorization.service";
import { AccountService } from "../../../../platform-pages/src/lib/pages/home-admin/home-admin-data/account.service";
import { Comment } from "../../../../platform-pages/src/lib/pages/home-admin/home-admin-data/account.types";
import { take } from "rxjs";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { NzModalService } from "ng-zorro-antd/modal";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "mh-account-comments",
  templateUrl: "./account-comments.component.html",
  styleUrls: ["./account-comments.component.less"],
})
export class AccountCommentsComponent implements OnInit {
  @Input() talentId!: string;
  formGroup: FormGroup;
  authorId: string | undefined;
  comments: Comment[] = [];
  editingCommentId: string | null = null;

  constructor(
    private authorizationService: AuthorizationService,
    private accountService: AccountService,
    private modal: NzModalService,
    private translateService: TranslateService,
  ) {
    this.authorizationService.getAccountId().subscribe((accountId) => {
      this.authorId = accountId;
    });
    this.formGroup = new FormGroup({
      text: new FormControl("", [Validators.required]),
    });
  }

  ngOnInit() {
    this.loadComments();
  }

  loadComments() {
    this.accountService
      .loadAccountComments(this.talentId)
      .pipe(take(1))
      .subscribe((comments) => {
        this.comments = comments;
      });
  }

  handleSubmit(): void {
    if (this.formGroup.invalid) {
      Object.values(this.formGroup.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
      return;
    }
    const text = this.formGroup.get("text")?.value;
    if (this.editingCommentId) {
      this.updateComment(this.editingCommentId, text);
    } else {
      this.addComment(text);
    }
  }

  addComment(text: string) {
    if (this.talentId && this.authorId) {
      this.accountService.sendAccountComment(this.talentId, text, this.authorId).subscribe((comment) => {
        if (comment) {
          this.formGroup.reset();
          this.comments.push(comment);
        }
      });
    }
  }

  updateComment(commentId: string, text: string) {
    this.accountService.updateAccountComment(commentId, text).subscribe((updatedComment) => {
      if (updatedComment) {
        const index = this.comments.findIndex((c) => c.commentId === commentId);
        if (index !== -1) {
          this.comments[index] = updatedComment;
        }
        this.formGroup.reset();
        this.editingCommentId = null;
      }
    });
  }

  startEditing(comment: Comment) {
    this.editingCommentId = comment.commentId;
    this.formGroup.patchValue({ text: comment.text });
  }

  cancelEditing() {
    this.editingCommentId = null;
    this.formGroup.reset();
  }

  confirmDeleteComment(commentId: string) {
    this.modal.confirm({
      nzTitle: this.translateService.instant("comment.confirm.delete.label"),
      nzOkText: this.translateService.instant("ok.button"),
      nzCancelText: this.translateService.instant("cancel.button"),
      nzOkType: "primary",
      nzOkDanger: true,
      nzOnOk: () => this.onDeleteComment(commentId),
    });
  }

  onDeleteComment(commentId: string) {
    this.accountService
      .deleteAccountComment(commentId)
      .pipe(take(1))
      .subscribe((success) => {
        if (success) {
          this.comments = this.comments.filter((comment) => comment.commentId !== commentId);
        }
      });
  }
}